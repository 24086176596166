import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /schools/create создание школы
 * @apiGroup schools and classes
 * @apiName создание школы
 *
 * @apiDescription <p>Страница создания школы</p>
 * <p>Компонент <code>SchoolForm.js</code> <a href="../client/src/pages/schools/SchoolForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /schools/:id редактирования школы
 * @apiGroup schools and classes
 * @apiName редактирования школы
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница редактирования школы</p>
 * <p>Компонент <code>SchoolForm.js</code> <a href="../client/src/pages/schools/SchoolForm.js">ссылка на файл</a></p>
 */

export const SchoolForm = () => {
    const { t } = useTranslation("schools");
    const history = useHistory();
    const schoolId = useParams().id;
    const [school, setSchool] = useState(null);
    const [name, setName] = useState("");

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`schools/${schoolId}`);
                setSchool(data);
                setName(data.name);
            })();
        }
    }, [schoolId]);

    const changeHandler = (event) => {
        setName(event.target.value);
    };

    const submit = async (e) => {
        e.preventDefault();
        if (school) {
            await axios.post(`schools/${school._id}`, { name });
        } else {
            await axios.post("schools/create", { name });
        }

        history.goBack();
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("schools")}</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{school ? t("change_school") : t("add_school")}</h3>
                    <div className="input-field">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder={t("name")}
                            onChange={changeHandler}
                            value={name}
                        />
                        <label htmlFor="name">{t("name")}</label>
                    </div>
                </div>

                <button onClick={submit} disabled={!name} className="main-btn">
                    {school ? t("change_school") : t("add_school")}
                </button>
            </div>
        </Layout>
    );
};
