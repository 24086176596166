import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { declinations } from "helpers/declinations";
import { yearsOldCounter } from "helpers/years";
import { Tools } from "components/Tools";
import { Histories } from "components/Histories";
import { Layout } from "components/Layout";
import { PupilProfile } from "components/profile/PupilProfile";
import { PsychProfile } from "components/profile/PsychProfile";
import { ProfileModals } from "components/profile/ProfileModals";
import "../static/scss/main.scss";
import { PupilAgreementsInProfile } from "components/profile/PupilAgreementsInProfile";

/**
 * @api {get} /dashboard профиль пользователя
 * @apiGroup profile
 * @apiName профиль пользователя
 *
 * @apiDescription <p>Страница профиля психолога или ученика</p>
 * <p>Компонент <code>ProfilePage.js</code> <a href="../client/src/pages/ProfilePage.js">ссылка на файл</a></p>
 */

export const ProfilePage = () => {
    const { role, user, isShowTgConnect } = useSelector((state) => state.auth);
    const { loading } = useSelector((state) => state.app);
    const { t, i18n } = useTranslation("profile");

    return (
        <Layout>
            <div
                className={`page page__small profile-page ${
                    role === "pupil" ? "profile-page_pupil" : "profile-page_psych"
                }`}
            >
                {role === "psych" && (
                    <Tools
                        tools={[
                            { url: "/meetings/schools", title: t("create_meeting") },
                            { url: "/tests/create", title: t("create_test") },
                        ]}
                    />
                )}

                <header className="page__header">
                    <Link
                        to="/settings"
                        className="icon-btn page__icon-btn page__icon-btn_right icon-btn_settings"
                    ></Link>
                    <p className="page__title profile-page__title">
                        {role === "pupil" ? t("pupil") : t("psychologist")}
                    </p>
                </header>

                <div className="profile-page__main">
                    <div className="profile-page__info">
                        <p className="profile-page__name">
                            {user?.surname} {user?.name} {user?.patronymic}
                        </p>
                        {user?.birthday && (
                            <p className="profile-page__birthday">
                                {yearsOldCounter(user?.birthday)}{" "}
                                {declinations(
                                    yearsOldCounter(user?.birthday),
                                    "year",
                                    i18n.language
                                )}
                            </p>
                        )}
                        {role === "pupil" && (
                            <>
                                <p className="profile-page__birthday">
                                    {t("your_psychologist")}: {user?.psychName}{" "}
                                    {user?.psychPatronymic}
                                </p>
                                {user?.psychSchedule && (
                                    <p className="profile-page__birthday">
                                        {t("psychologist_schedule")}:
                                        <br />
                                        <span className="profile-page__schedule">
                                            {user?.psychSchedule}
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {user?.status && user?.status !== "New" && (
                    <ProfileModals
                        user={user}
                        role={role}
                        isShowTgConnect={isShowTgConnect}
                        t={t}
                    />
                )}

                {role === "pupil" &&
                    (user?.status === "New" ? (
                        <PupilAgreementsInProfile role={role} user={user} />
                    ) : (
                        <PupilProfile user={user} loading={loading} />
                    ))}

                {role === "psych" && (
                    <>
                        <PsychProfile loading={loading} />
                        <Histories />
                    </>
                )}
            </div>
        </Layout>
    );
};
