import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { hideNotice } from "redux/actions/setAppActions";
import { Alert } from "components/Alert";
import { Notice } from "components/Notice";
import { RegisterPsychForm } from "components/register/RegisterPsychForm";

/**
 * @api {get} /psych/register регистрация психолога
 * @apiGroup psychologists
 * @apiName регистрация психолога
 *
 * @apiDescription <p>Страница регистрации психолога</p>
 * <p>Компонент <code>RegisterPsych.js</code> <a href="../client/src/pages/RegisterPsych.js">ссылка на файл</a></p>
 */

export const RegisterPsych = () => {
    const { t } = useTranslation("registration");
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { alert, notice } = useSelector((state) => state.app);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isShowHeader, setIsShowHeader] = useState(true);

    const hideNoticeHandler = () => {
        dispatch(hideNotice());

        if (isSuccess) {
            window.location.href = "/";
        }
    };

    const backHandler = () => {
        if (search) {
            const searchParams = new URLSearchParams(search);
            const step = searchParams.get("step");

            if (step) {
                history.push("/psych/register");
            }
        } else {
            history.push("/");
        }
    };

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="page auth-page">
                <header className="page__header">
                    <button
                        onClick={backHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("specialist_registration")}</p>
                </header>

                <div className="auth-page__wrapper">
                    {isShowHeader && (
                        <div className="auth-page__header">
                            <h3>{t("specialist_registration")}</h3>
                            <Link to="/psych/login" className="auth-page__header-btn">
                                {t("got_account")}
                            </Link>
                        </div>
                    )}

                    <RegisterPsychForm
                        setIsSuccess={setIsSuccess}
                        showHeader={() => setIsShowHeader(true)}
                        hideHeader={() => setIsShowHeader(false)}
                    />
                </div>
            </div>
        </div>
    );
};
