import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";

import { Layout } from "components/Layout";

/**
 * @api {get} /schools/:id/classes список классов
 * @apiGroup schools and classes
 * @apiName список классов
 *
 *  @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница со списком классов выбранной школы</p>
 * <p>Компонент <code>Classes.js</code> <a href="../client/src/pages/classes/Classes.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/schools/:id/classes список классов при создании встречи
 * @apiGroup meetings
 * @apiName список классов при создании встречи
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница со списком классов при создании встречи</p>
 * <p>Компонент <code>Classes.js</code> <a href="../client/src/pages/classes/Classes.js">ссылка на файл</a></p>
 */

export const Classes = () => {
    const { t } = useTranslation("classes");
    const history = useHistory();
    const schoolId = useParams().id;
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`schools/${schoolId}/classes`);
                setClasses(data);
            })();
        }
    }, [schoolId]);
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => history.push("/schools")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{classes[0]?.school.name}</p>
                    {schoolId && (
                        <Link
                            to={`/schools/${schoolId}`}
                            className="icon-btn page__icon-btn page__icon-btn_right"
                        >
                            <FaPen />
                        </Link>
                    )}
                </header>

                <div className="school-page__wrapper">
                    <div className="list">
                        {classes.map((item) => (
                            <div
                                key={item._id}
                                onClick={() => {
                                    if (window.location.href.indexOf("/meetings/schools/") !== -1) {
                                        history.push(`/meetings/classes/${item._id}/pupils`);
                                    } else {
                                        history.push(`/classes/${item._id}/pupils`);
                                    }
                                }}
                                className="list__item"
                            >
                                <p>
                                    {item.number} {item.letter}
                                </p>
                                <p className="list__desc">
                                    {t("pupils_number")}: {item.pupils.length}, {t("pupils_max")}:{" "}
                                    {item.max}
                                </p>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/classes/${item._id}/code`);
                                    }}
                                    className="school-page__qr-icon"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {schoolId && (
                    <Link to={`/schools/${schoolId}/classes/create`} className="main-btn">
                        {t("add_class")}
                    </Link>
                )}
            </div>
        </Layout>
    );
};
