import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { IMaskInput } from "react-imask";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { SureAlert } from "components/SureAlert";
import { Input } from "components/forms/Input";
import { RegisterPsychRegionSelect } from "components/register/RegisterPsychRegionSelect";
import { SITE_URL, IS_PRODUCTION } from "config";
import { DNEVNIKRU_LOGIN_URL } from "configs/dnevnikru";
import { classNames } from "helpers/classNames";
import { psychRegisterFormsValidators } from "helpers/formsValidators";
import { getFormDataFromDnevnikru } from "helpers/registration";
import { validatePhone } from "helpers/validators";
import { finishLoading, showAlert, showNotice, startLoading } from "redux/actions/setAppActions";
import { selectUser } from "redux/authReducer";
import terms from "static/docs/LSSR_terms_of_use.pdf";
import policy from "static/docs/policy.pdf";
import checkIcon from "static/img/check-circle.svg";
import { RegisterPsychPhoneVerification } from "./RegisterPsychPhoneVerification";
import { Loader } from "components/Loader";

const initialState = {
    sex: "1",
    login: "",
    email: "",
    name: "",
    surname: "",
    patronymic: "",
    birthday: "",
    region: "",
    city: "",
    phone: "",
    institution: "",
};

const dnevnikruLoginUrl = `${DNEVNIKRU_LOGIN_URL}&redirect_uri=${SITE_URL}/psych/register`;
const minPsychBirthYear = new Date().getFullYear() - 18;

export const RegisterPsychForm = ({ setIsSuccess, showHeader, hideHeader }) => {
    const { t } = useTranslation(["registration", "translation"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const { hash, search } = useLocation();
    const { dnevnikruUser } = useSelector(selectUser);
    const { loading } = useSelector((state) => state.app);
    const [form, setForm] = useState(initialState);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [thirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
    const [fourthCheckboxChecked, setFourthCheckboxChecked] = useState(false);
    const [incorrectFields, setIncorrectFields] = useState([]);
    const [isSetRegion, setIsSetRegion] = useState(false);
    const [isSetPhoneConfirm, setIsSetPhoneConfirm] = useState(false);
    const [isPhoneConfirmSureAlert, setIsPhoneConfirmSureAlert] = useState(false);
    const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
    const formFields = {
        surname: t("surname"),
        name: t("name"),
        birthday: t("birthday"),
        login: t("login"),
        email: t("email"),
        phone: t("phone"),
        region: t("region"),
        institution: t("institution"),
        phoneVerification: t("phone_verification_title"),
    };

    useEffect(() => {
        const savedForm = localStorage.getItem("psychRegisterForm");

        if (savedForm) setForm(JSON.parse(savedForm));
    }, []);

    useEffect(() => {
        if (hash) {
            const accessToken = hash.match(/access_token=(.*)&state=/);
            const error = hash.match(/error=(.*)&state=/);

            if (accessToken) {
                if (!accessToken[1]) {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: t("dnevnikru_integration_error", { ns: "translation" }),
                        })
                    );

                    return;
                }

                (async () => {
                    try {
                        dispatch(startLoading());
                        const { data } = await axios.get(
                            `/psych/register/dnevnikru?accessToken=${accessToken[1]}`
                        );
                        const formData = getFormDataFromDnevnikru(
                            {
                                ...data,
                                accessToken: accessToken[1],
                            },
                            "psych"
                        );

                        if (formData) {
                            setForm((prevState) => ({ ...prevState, ...formData }));
                        }
                    } catch (e) {
                        console.log(e);
                        if (e.response?.data?.message) {
                            dispatch(showAlert({ type: "error", text: e.response.data.message }));
                        } else {
                            dispatch(
                                showAlert({
                                    type: "error",
                                    text: t("incorrect_data", { ns: "translation" }),
                                })
                            );
                        }
                    } finally {
                        dispatch(finishLoading());
                    }
                })();
            } else if (error) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("dnevnikru_integration_error", { ns: "translation" }),
                    })
                );
            }
        }
    }, [history, hash, dispatch, t]);

    useEffect(() => {
        if (search) {
            const searchParams = new URLSearchParams(search);
            const step = searchParams.get("step");

            if (step === "region") {
                setIsSetRegion(true);
                hideHeader();
            } else if (step === "phone" && !isPhoneConfirmed) {
                setIsSetPhoneConfirm(true);
                hideHeader();
            }
        } else {
            setIsSetRegion(false);
            setIsSetPhoneConfirm(false);
            showHeader();
        }
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dnevnikruUser) {
            const formData = getFormDataFromDnevnikru(dnevnikruUser, "psych");

            if (formData) {
                setForm((prevState) => ({ ...prevState, ...formData }));
            }
        }
    }, [dnevnikruUser]);

    const registerHandler = async () => {
        if (
            firstCheckboxChecked &&
            secondCheckboxChecked &&
            thirdCheckboxChecked &&
            fourthCheckboxChecked
        ) {
            if (
                form.birthday &&
                moment(form.birthday, "DD.MM.YYYY", true).year() >= minPsychBirthYear
            ) {
                dispatch(
                    showNotice(
                        "Здесь регистрация только для сотрудников, чтобы получить логин и пароль обратитесь к вашему Педагогу-психологу"
                    )
                );

                return;
            }

            const errorFields = psychRegisterFormsValidators(form, isPhoneConfirmed);

            if (!errorFields.length) {
                try {
                    const data = { ...form };
                    let ISODate = moment(data.birthday, "DD.MM.YYYY", true).toISOString();
                    if (ISODate) {
                        data.birthday = ISODate;
                    }

                    await axios.post("psych/register", { ...data });

                    setForm(initialState);
                    dispatch(showNotice(t("psychologist_success_message")));
                    localStorage.removeItem("psychRegisterForm");
                    setIsSuccess(true);
                } catch (e) {
                    if (e.response?.data?.message) {
                        dispatch(showNotice(e.response.data.message));
                    } else {
                        dispatch(showNotice(e.message));
                    }
                }
            } else {
                const incorrectFieldsMessage = errorFields
                    .map((item) => formFields[item])
                    .join("\n");
                setIncorrectFields(errorFields);
                dispatch(
                    showAlert({
                        type: "error",
                        text: `${t("incorrectly_filled_fields", {
                            ns: "translation",
                        })}:\n\n${incorrectFieldsMessage}`,
                    })
                );
            }
        }
    };

    const changeHandler = (e) => {
        let { name, value } = e.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        const newValue = ["institution", "birthday", "phone"].includes(name) ? value : value.trim();
        const formData = {
            ...form,
            [name]: newValue,
        };

        localStorage.setItem("psychRegisterForm", JSON.stringify(formData));
        setForm(formData);
    };

    const setRegionHandler = (region, city) => {
        if (incorrectFields.includes("region")) {
            setIncorrectFields(incorrectFields.filter((item) => item !== "region"));
        }
        const formData = {
            ...form,
            region,
            city,
        };

        localStorage.setItem("psychRegisterForm", JSON.stringify(formData));
        setForm(formData);
        history.push("/psych/register");
    };

    const showPhoneConfirmFormHandler = () => {
        setIsPhoneConfirmSureAlert(true);
    };

    const confirmPhoneHandler = () => {
        setIsPhoneConfirmSureAlert(false);
        history.push("/psych/register?step=phone");
    };

    const successPhoneVerification = () => {
        setIsPhoneConfirmed(true);
        history.push("/psych/register");
    };

    if (isSetRegion) {
        return <RegisterPsychRegionSelect setRegion={setRegionHandler} />;
    }

    if (isSetPhoneConfirm) {
        return (
            <RegisterPsychPhoneVerification
                phone={form.phone}
                setPhoneVerification={successPhoneVerification}
            />
        );
    }

    return (
        <>
            {isPhoneConfirmSureAlert && (
                <SureAlert
                    title={t("phone_verification_title")}
                    text={t("phone_verification_text")}
                    submitText={t("phone_verification_btn")}
                    handleClose={() => setIsPhoneConfirmSureAlert(false)}
                    handleSubmit={confirmPhoneHandler}
                />
            )}

            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="auth-page__input-group auth-page__input-group_row">
                        <div className="input-field">
                            <input
                                id="m"
                                type="radio"
                                name="sex"
                                value="1"
                                checked={form.sex === "1"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="m">{t("male")}</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="f"
                                type="radio"
                                name="sex"
                                value="2"
                                checked={form.sex === "2"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="f">{t("female")}</label>
                        </div>
                    </div>
                    <div className="auth-page__input-group">
                        <Input
                            id="surname"
                            type="text"
                            name="surname"
                            placeholder={t("surname")}
                            onChange={changeHandler}
                            value={form.surname}
                            isError={incorrectFields.includes("surname")}
                        />
                        <Input
                            id="name"
                            type="text"
                            name="name"
                            onChange={changeHandler}
                            placeholder={t("name")}
                            value={form.name}
                            isError={incorrectFields.includes("name")}
                        />
                        <Input
                            id="patronymic"
                            type="text"
                            name="patronymic"
                            onChange={changeHandler}
                            placeholder={t("patronymic")}
                            value={form.patronymic}
                            isError={incorrectFields.includes("patronymic")}
                        />
                        <Input
                            id="birthday"
                            type="text"
                            name="birthday"
                            placeholder={t("birthday")}
                            onChange={changeHandler}
                            value={form.birthday}
                            isError={incorrectFields.includes("birthday")}
                            mask={Date}
                            maskOptions={{
                                min: new Date(1950, 0, 1),
                                max: new Date(minPsychBirthYear, 0, 1),
                                unmask: true,
                            }}
                        />
                        <Input
                            id="login"
                            type="text"
                            name="login"
                            placeholder={t("login")}
                            onChange={changeHandler}
                            value={form.login}
                            isError={incorrectFields.includes("login")}
                        />
                        <Input
                            id="email"
                            type="text"
                            name="email"
                            placeholder={t("email")}
                            onChange={changeHandler}
                            value={form.email}
                            isError={incorrectFields.includes("email")}
                        />
                        <div
                            className={classNames("input-field input-field__phone-confirm", {
                                error: incorrectFields.includes("phone"),
                            })}
                        >
                            <IMaskInput
                                id="phone"
                                type="text"
                                name="phone"
                                placeholder={t("phone")}
                                onChange={changeHandler}
                                value={form.phone}
                                mask="+{7}(000)000-00-00"
                                readOnly={isPhoneConfirmed}
                            />

                            {isPhoneConfirmed ? (
                                <div className="input-field__phone-confirm-succes">
                                    {t("phone_confirm_success")}
                                    <img src={checkIcon} alt="" />
                                </div>
                            ) : (
                                <button
                                    onClick={showPhoneConfirmFormHandler}
                                    className="input-field__phone-confirm-button"
                                    disabled={!validatePhone(form.phone)}
                                >
                                    {t("phone_confirm_btn")}
                                </button>
                            )}
                        </div>
                        <Input
                            id="region"
                            type="text"
                            name="region"
                            placeholder={t("region")}
                            onClick={() => history.push("/psych/register?step=region")}
                            value={form.city ? `${form.city}, ${form.region}` : ""}
                            isError={incorrectFields.includes("region")}
                            readOnly
                        />
                        <Input
                            id="institution"
                            type="text"
                            name="institution"
                            placeholder={t("institution")}
                            onChange={changeHandler}
                            value={form.institution}
                            isError={incorrectFields.includes("institution")}
                        />
                    </div>

                    {!dnevnikruUser && !IS_PRODUCTION && (
                        <div className="auth-page__register-buttons">
                            <a href={dnevnikruLoginUrl} className="auth-page__dnevnikru">
                                <img
                                    src="https://static.dnevnik.ru/img/logotypes/logotype.png"
                                    alt="Дневник.ру"
                                />
                                <br />
                                <span>Привязать аккаунт</span>
                            </a>
                        </div>
                    )}

                    <div className="landing-contacts__col">
                        <div className="animatedCheckbox">
                            <div
                                style={
                                    firstCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={() => setFirstCheckboxChecked(!firstCheckboxChecked)}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_1a")}{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    {t("user_agreement_1")}
                                </a>{" "}
                                {t("terms_of_use_1b")}
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    secondCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={() => setSecondCheckboxChecked(!secondCheckboxChecked)}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_1a")}{" "}
                                <a href={policy} target="_blank" rel="noreferrer">
                                    {t("policy_of_personal_data")}
                                </a>
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    thirdCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={() => setThirdCheckboxChecked(!thirdCheckboxChecked)}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_2a")}{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    {t("user_agreement_2")}
                                </a>{" "}
                                {t("terms_of_use_2b")}
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    fourthCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={() => setFourthCheckboxChecked(!fourthCheckboxChecked)}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>{t("terms_of_use_3")}</p>
                        </div>
                    </div>
                    <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                        {t("terms_of_use_4a")}{" "}
                        <a href={policy} target="_blank" rel="noreferrer">
                            {t("policy_of_personal_data")}
                        </a>{" "}
                        {t("terms_of_use_4b")}{" "}
                        <a href={terms} target="_blank" rel="noreferrer">
                            {t("user_agreement_2")}
                        </a>
                    </p>
                    <button
                        style={
                            firstCheckboxChecked &&
                            secondCheckboxChecked &&
                            thirdCheckboxChecked &&
                            fourthCheckboxChecked
                                ? {}
                                : { background: "gray", boxShadow: "none" }
                        }
                        onClick={registerHandler}
                        className="main-btn auth-page__btn"
                    >
                        {t("registration_button")}
                    </button>
                </>
            )}
        </>
    );
};
