import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useScrollToTop } from "hooks/useScrollToTop";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /instruction инструкция
 * @apiGroup profile
 * @apiName инструкция
 *
 * @apiDescription <p>Страница с инструкциями для психологов и учеников</p>
 * <p>Компонент <code>InstructionPage.js</code> <a href="../client/src/pages/InstructionPage.js">ссылка на файл</a></p>
 */

export const InstructionPage = () => {
    const { i18n } = useTranslation();
    const { role } = useSelector((state) => state.auth);
    const isEng = i18n.language === "en";

    useScrollToTop();

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />

                    <p className="page__title">{isEng ? "Instruction" : "Инструкция"}</p>
                </header>

                {isEng ? (
                    <div className="instruction-page__text">
                        <h3 style={{ textAlign: "center", marginTop: 50 }}>Coming soon...</h3>
                    </div>
                ) : (
                    <>
                        {role === "psych" && <InstructionPsych />}
                        {role === "pupil" && <InstructionPupil />}
                    </>
                )}
            </div>
        </Layout>
    );
};

function InstructionPsych() {
    return (
        <div className="instruction-page__text">
            <p>Добрый день, Коллеги!</p>

            <p>
                Спасибо вам за выбор TestU.online – платформы автоматизации психодиагностики
                подростков, сотрудников и родителей.
            </p>

            <h4>Решение предполагает широкий набор функций, включающий:</h4>

            <p>
                - Назначение и прохождение методик для подростков в удобном интерфейсе
                <br />- Аналитика результатов тестирования по каждому ученику, сводные отчеты по
                всей школе и классам или другим группам респондентов
                <br />- Система рекомендаций и интерпретаций по результатам тестирования для
                психолога
                <br />- Набор валидированных проблемно-ориентированных методик
                <br />- Функционал для ученика обратиться с запросом на разговор со специалистом по
                нажатию одной кнопки
                <br />- Конструктор тестов для компьютеризации широкого класса методик
            </p>

            <p>
                Подробные инструкции Вы можете посмотреть по следующей ссылке:
                <a
                    href="https://www.youtube.com/playlist?list=PLYxJa_0Ez7spOU--WUfyAhzmWJhhQQbg7 "
                    target="_blank"
                    rel="noreferrer"
                    className="youtube-link"
                >
                    посмотреть видеоинструкцию
                </a>
            </p>

            <p>
                Когда вы войдете в личный кабинет, вам нужно добавить свою школу. Для этого нажмите
                на “Добавление контингента учеников” и далее нажмите на синюю кнопку “Добавить мою
                организацию” внизу экрана и внесите данные о школе.
            </p>

            <p>
                После нажмите на школу, которую добавили. Добавьте нужные классы, для этого нажмите
                на синюю кнопку “Добавить класс” внизу экрана, заполните данные о классе и нажмите
                на синюю кнопку “Добавить класс” внизу экрана.
            </p>

            <p>
                После для каждого класса добавьте учеников, для этого нажмите на кнопку с черным
                значком QR кода справа от класса.
            </p>

            <p>
                Вам откроется большой QR-код, под ним кнопка «Добавить через список» - нажимая на
                кнопку вы сможете «Скачать шаблон», внутри этого файла внесите информацию по
                контингенту, загрузите файл обратно в систему по кнопке «загрузить список учеников»
                и у вас появится файл с логинами и паролями для обучающихся для входа в систему –
                можете раздать им. Сам же файл доступен только для вас.
            </p>

            <p>
                QR и ссылка внизу страницы необходимы для добавления учеников через сканирование QR
                кода с телефона, или перехода по ссылке при удаленной учебе. В этом случае сами
                ученики придумывают логин и пароль для входа в систему (при этом, если они забудут
                пароль, то вы всегда можете восстановить пароль через свой личный кабинет).
            </p>

            <p>
                После того, как ученики зарегистрируются в системе – они смогут пройти тесты,
                которые вы им назначите. Назначить тест можно через раздел «Проведение
                психодиагностики» и выбрать необходимую иерархию методик, а затем и назначить
                методику определенной выборке респондентов.
            </p>

            <h4>Результаты психодиагностики</h4>

            <p>
                После тестирования мы можете перейти в раздел «Результаты психодиагностики», где вы
                можете последовательно выбрать группу респондентов, для которой хотите видеть
                результат, включая фильтрацию по конкретному тесту, шкале и уровню.{" "}
                <b>Фильтрация доступна в правом верхнем углу раздела.</b>
            </p>

            <p>
                Результаты можно смотреть по каждому ученику в отдельности, поэтому наша
                рекомендация – это настраивать фильтры по уровням чтобы выделять список подростков в
                группе риска, а затем на странице каждого ученика отщелкивать галочку «Группа риска»
                в соответствующем разделе. <b>Фильтрация доступна в правом верхнем углу раздела.</b>
            </p>

            <p>
                У учеников существует функция «Хочу поговорить», кнопка расположена в личном
                кабинете ученика на главной странице. При нажатии на неё – вы как специалист
                получите уведомление у себя в личном кабинете, что определенный подросток хотел бы
                что-то обсудить. Этот функционал не предназначен для экстренной помощи, но в рамках
                плановых запросов от подростков позволяет им легко заявить о своем запросе на беседу
                с психологом.
            </p>

            <p>
                «Блок заметки» - вы можете осуществлять записи по каждому ученику, если это
                необходимо. Данный блок не доступен для учеников.
            </p>

            <p>
                «Блок рекомендации» - вы можете осуществлять записи в формате рекомендации по
                каждому ученику, если это необходимо. Данный блок доступен для учеников.
            </p>

            <p>
                По любым вопросам технического характера, сбоев вы можете обращаться по форме
                обратной связи через Личный кабинет или по телефону:{" "}
                <a href="tel:+74951207004">+7 (495) 120-70-04</a> , Валерий
            </p>

            <p>
                По вопросам предложений развития платформы и важных для вас необходимых функций вы
                можете обращаться также через форму на главной странице вашего личного кабинета.
            </p>

            <h4>Информация по формированию отчетов:</h4>
            <p style={{ marginLeft: 15 }}>
                1. Генерация отчёта по классу по всем методикам (без каких-либо фильтров):
            </p>
            <p>
                По каждой методике создаётся лист. В нём идёт список учеников, которым когда-либо
                назначалась соответствующая методика. Результаты каждого ученика идут по последней
                назначенной методике. Если ученик не прошёл последнюю назначенную методику, то в его
                результатах напишет "Отсутствует". Дальше по ученикам, у которых есть результаты,
                считается среднее и строятся диаграммы.
            </p>
            <p style={{ marginLeft: 15 }}>
                2. Генерация по отчёту по классу по выбранной методике (указана дата и методика)
            </p>
            <p>
                По методике создаётся лист. Для каждого ученика, которому в соответствующую дату
                была назначена методика выводится результат по этой методике. Если ученик не прошёл
                методику, то в его результатах напишет "Отсутствует". Дальше по ученикам, у которых
                есть результаты, считается среднее и строятся диаграммы.
            </p>
            <p style={{ marginLeft: 15 }}>3. Генерация отчёта по ученику по всем методикам:</p>
            <p>
                Для ученика берутся все методики, пишется результат по крайнему решению для каждой
                методики. Если ученик никогда не решал методику, то она в отчёте не пишется. Для
                каждой методики выводится среднее по классу. Среднее значение считается по крайним
                результатам каждого ученика из класса ученика, по которому генерируем отчёт
                (результат ученика, по которому генерируем тоже учитывается). Ниже строятся
                диаграммы по результатам.
            </p>
            <p style={{ marginLeft: 15 }}>
                4. Генерация отчёта по одному ученику по одной методике:
            </p>
            <p>
                Для ученика пишется результат по крайнему решению методики. Для методики выводится
                среднее по классу. Среднее значение считается по крайним результатам каждого ученика
                из класса ученика, по которому генерируем отчёт (результат ученика, по которому
                генерируем тоже учитывается). Ниже строятся диаграммы по результатам.
            </p>
        </div>
    );
}

function InstructionPupil() {
    return (
        <div className="instruction-page__text">
            <p>Добрый день!</p>
            <p>
                Наше решение позволяет быстро и эффективно проводить различного типа анкетирования и
                выстроить твой процесс коммуникации с психологом безопасно и конфиденциально. Доступ
                ко всей информации имеет только специалист твоего учреждения.
            </p>
            <p>
                Советуем запомнить логин и пароль, придуманные для входа в систему. Но если вдруг не
                помнишь – обратись к специалисту в своей организации, он(а) может обновить твои
                доступы в систему.
            </p>

            <p>
                В блоке «Тесты» будут появляться тесты, которые назначит педагог-психолог.
                Результаты будут доступны только специалисту. Не переживай за результаты и
                правильные, неправильные ответы – в любом случае всё будет хорошо.
            </p>

            <p>
                Если у тебя есть запрос на разговор с педагогом-психологом – ты можешь нажать кнопку
                «Хочу поговорить», данный функционал предназначен не для экстренного решения
                проблем, а для того, чтобы у тебя была возможность безопасно заявить о наличии
                запроса на разговор и затем специалист самостоятельно конфиденциально вышел на
                контакт с тобой.
            </p>

            <p>
                В блоке «Рекомендации» специалист твоего учреждения сможет формировать
                индивидуальные рекомендации, в этом разделе ты сможешь с ними ознакомиться. В блоке
                «встречи» ты можешь видеть назначенные встречи специалистом. Если время и дата
                удобны тебе – подтверди их в системе и приходи в назначенное время для беседы.
            </p>

            <p>
                Мы работаем над улучшением продукта и добавлением полезных функций. Будем рады
                обратной связи, если у тебя есть идеи как можно улучшить наш продукт: добавить
                интересные контент по теме, методики по профориентации или что-то дополнительное.
            </p>

            <p>
                По любым вопросам технического характера, сбоев вы можете обращаться по почте{" "}
                <a href="mailto:help@testu.online">help@testu.online</a>
            </p>
            <p>
                По вопросам предложений развития платформы и важных для вас необходимых функций вы
                можете обращаться по почте –{" "}
                <a href="mailto:school@testu.online">school@testu.online</a>
            </p>
        </div>
    );
}
