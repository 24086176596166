import React from "react";
import { useTranslation } from "react-i18next";

import { checkIosPwa } from "helpers/devices";
import { downloadFileInPWA } from "helpers/pwa";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { guidelinesList } from "components/profile/guidelinesList";

export const GuidelinesPage = () => {
    const { t } = useTranslation("profile");

    const handleClick = async (fileInfo) => {
        if (!fileInfo) return;

        if (checkIosPwa()) {
            await downloadFileInPWA(fileInfo.file, fileInfo.fileName);
        } else {
            window.open(fileInfo.file, "_blank");
        }
    };

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{t("guidelines")}</p>
                </header>

                <div className="workWithMethods-page__wrapper">
                    <div className="list workWithMethods-page__list">
                        {guidelinesList.map((item) => (
                            <button
                                onClick={() => handleClick(item)}
                                className="main-btn"
                                key={item.title}
                            >
                                {item.title}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
