import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { getPupil } from "redux/actions/setPupilActions";
import { getTestsBiPupilId } from "redux/actions/setTestActions";
import { getNotes } from "redux/actions/setNoteActions";
import { getAdvices } from "redux/actions/setAdviceActions";
import { getMeetings } from "redux/actions/setMeetingActions";
import { finishLoading, showAlert, startLoading } from "redux/actions/setAppActions";
import { useCreateReport } from "hooks/useCreateReport";
import { yearsOldCounter } from "helpers/years";
import { declinations } from "helpers/declinations";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import { Tools } from "components/Tools";
import { SureAlert } from "components/SureAlert";
import { PupilTools } from "components/PupilTools";
import { GET_PUPIL } from "redux/types";
import tgIcon from "static/img/ic-telegram-app.svg";

/**
 * @api {get} /pupils/:id страница ученика
 * @apiGroup pupils
 * @apiName страница ученика
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница ученика</p>
 * <p>Компонент <code>PupilPage.js</code> <a href="../client/src/pages/PupilPage.js">ссылка на файл</a></p>
 */

export const PupilPage = () => {
    const pupilId = useParams().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation("pupils");
    const { notPassedTests, tests } = useSelector((state) => state.test);
    const { pupilData } = useSelector((state) => state.pupil);
    const { advices } = useSelector((state) => state.advice);
    const { meetings } = useSelector((state) => state.meeting);
    const { notes } = useSelector((state) => state.note);
    const { loading } = useSelector((state) => state.app);
    const [isPassedTests, setIsPassedTests] = useState(false);
    const { createReport, isReportLoading } = useCreateReport();
    const [isDeleteSureAlert, setIsDeleteSureAlert] = useState(false);
    const [isMoveToArchiveSureAlert, setIsMoveToArchiveSureAlert] = useState(false);
    const [isMoveFromArchiveSureAlert, setIsMoveFromArchiveSureAlert] = useState(false);
    const [tools, setTools] = useState([
        {
            url: `/pupils/${pupilId}/parent-contacts`,
            title: t("contacts_of_parents"),
        },
        {
            url: `/pupils/${pupilId}/accesses`,
            title: t("view_accesses"),
        },
        {
            action: isShowMoveToArchiveHandler,
            title: t("move_to_archive"),
        },
        {
            action: isShowDeleteHandler,
            title: t("delete_pupil"),
        },
    ]);

    useEffect(() => {
        dispatch(getTestsBiPupilId(pupilId));
        dispatch(getAdvices(pupilId));
        dispatch(getMeetings(pupilId));
        dispatch(getNotes(pupilId));
        dispatch(getPupil(pupilId));
    }, [dispatch, pupilId]);

    useEffect(() => {
        const updatedTools = [...tools];

        if (pupilData?.isArchive) {
            updatedTools[2] = {
                action: isShowMoveFromArchiveHandler,
                title: t("move_from_archive"),
            };
        } else {
            updatedTools[2] = {
                action: isShowMoveToArchiveHandler,
                title: t("move_to_archive"),
            };
        }

        setTools(updatedTools);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pupilData]);

    useEffect(() => {
        if (tests.length) {
            const passedTests = tests.filter(({ solutionToken }) => solutionToken);
            setIsPassedTests(!!passedTests.length);
        } else if (isPassedTests) {
            setIsPassedTests(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tests]);

    const createReportHandler = () => {
        const patronymic = pupilData.patronymic ? `_${pupilData.patronymic}` : "";
        const fileName = `${t("report")}_${pupilData.surname}_${pupilData.name}${patronymic}.xlsx`;

        createReport(`statistic/pupil-report/${pupilId}`, fileName);
    };

    function isShowDeleteHandler() {
        setIsDeleteSureAlert(true);
    }

    function isShowMoveToArchiveHandler() {
        setIsMoveToArchiveSureAlert(true);
    }

    function isShowMoveFromArchiveHandler() {
        setIsMoveFromArchiveSureAlert(true);
    }

    const deletePupil = async () => {
        setIsDeleteSureAlert(false);

        try {
            dispatch(startLoading());
            await axios.delete(`pupil/${pupilId}`);

            dispatch(finishLoading());
            window.location.replace(`/classes/${pupilData.class._id}/pupils`);
        } catch (err) {
            const text = err.response?.data?.message || t("delete_pupil_error");
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text }));
        }
    };

    const movePupilToFromArchive = async (isArchive) => {
        if (isArchive) {
            setIsMoveToArchiveSureAlert(false);
        } else {
            setIsMoveFromArchiveSureAlert(false);
        }

        try {
            const { data } = await axios.put(`pupil/${pupilId}`, {
                isArchive,
                classId: pupilData.class._id,
            });

            const text = isArchive ? t("move_to_archive_success") : t("move_from_archive_success");

            dispatch(showAlert({ type: "success", text }));
            dispatch({ type: GET_PUPIL, payload: data });
        } catch (err) {
            const text = err.response?.data?.message || t("delete_pupil_error");
            dispatch(showAlert({ type: "error", text }));
        }
    };

    const backBtnHandler = () => {
        const classId = pupilData?.class?._id;
        const link = classId ? `/classes/${classId}/pupils` : -1;

        history.push(link);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            {pupilData && (
                <>
                    <Tools tools={tools} />

                    {isDeleteSureAlert && (
                        <SureAlert
                            type="deletePupil"
                            submitText={t("delete_pupil_button")}
                            handleClose={() => setIsDeleteSureAlert(false)}
                            handleSubmit={deletePupil}
                        />
                    )}

                    {isMoveToArchiveSureAlert && (
                        <SureAlert
                            text={t("sure_move_to_archive")}
                            submitText={t("move")}
                            handleClose={() => setIsMoveToArchiveSureAlert(false)}
                            handleSubmit={() => movePupilToFromArchive(true)}
                        />
                    )}

                    {isMoveFromArchiveSureAlert && (
                        <SureAlert
                            text={t("sure_move_from_archive")}
                            submitText={t("move")}
                            handleClose={() => setIsMoveFromArchiveSureAlert(false)}
                            handleSubmit={() => movePupilToFromArchive(false)}
                        />
                    )}

                    <div className={`page page__small profile-page 'profile-page_pupil'`}>
                        <header className="page__header">
                            <button
                                onClick={backBtnHandler}
                                className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                            />
                            <p className="page__title profile-page__title">{t("pupil")}</p>
                        </header>

                        <div className="profile-page__main">
                            <div className="profile-page__info">
                                <p className="profile-page__name">
                                    {pupilData.surname} {pupilData.name} {pupilData?.patronymic}
                                </p>
                                {pupilData.birthday && (
                                    <p className="profile-page__birthday">
                                        <span>
                                            {pupilData.class?.number} {pupilData.class?.letter}
                                        </span>
                                        ,{" "}
                                        <span>
                                            {yearsOldCounter(pupilData.birthday)}{" "}
                                            {declinations(
                                                yearsOldCounter(pupilData.birthday),
                                                "year",
                                                i18n.language
                                            )}
                                        </span>
                                    </p>
                                )}

                                <div className="profile-page__pupil-buttons">
                                    <PupilTools tools={tools} btnText={t("pupil_management_btn")} />

                                    {pupilData?.telegram && (
                                        <a
                                            href={`https://t.me/${pupilData.telegram}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="profile-page__pupil-tg"
                                        >
                                            <img src={tgIcon} alt="" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="profile-page__entities">
                            {isPassedTests && (
                                <div
                                    className="statistic-reports__btn statistic-reports__btn-pupil-profile"
                                    onClick={createReportHandler}
                                >
                                    {isReportLoading ? (
                                        <p>{t("report_loading", { ns: "translation" })}</p>
                                    ) : (
                                        <p>{t("create_report", { ns: "translation" })}</p>
                                    )}
                                </div>
                            )}

                            <Link
                                to={`/pupils/${pupilId}/categories`}
                                className={`profile-page__psych-alarm`}
                            >
                                <div>
                                    <p>{t("risk_groups", { ns: "translation" })}</p>
                                    <p>{t("specify_risk_groups")}</p>
                                </div>
                                <span>{pupilData.categories.length}</span>
                            </Link>

                            <div className="profile-page__entities-wrapper">
                                <Link
                                    to={`/tests?pupilId=${pupilId}&schoolId=${pupilData.class.school}&classId=${pupilData.class._id}`}
                                    className={`entity ${!!notPassedTests && "entity_test"}`}
                                >
                                    <p
                                        className={`entity__title ${
                                            !!notPassedTests && "entity__title_white"
                                        }`}
                                    >
                                        {t("tests")}
                                    </p>
                                    {!notPassedTests && (
                                        <p className="entity__description">{t("no_tests")}</p>
                                    )}
                                    <span
                                        className={`entity__extra ${
                                            !notPassedTests && "entity__extra_pupil-null"
                                        }`}
                                    >
                                        {notPassedTests}
                                    </span>
                                </Link>
                                <Link
                                    to={`/pupils/${pupilId}/meetings`}
                                    className={`entity ${"entity_meeting"}`}
                                >
                                    <p
                                        className={`entity__title 
                            ${"entity__title_white"}`}
                                    >
                                        {t("meetings")}
                                    </p>
                                    <span
                                        className={`entity__extra 
                            ${!meetings.length}`}
                                    >
                                        {meetings.length}
                                    </span>
                                </Link>
                                <Link
                                    to={`/advices?pupilId=${pupilId}`}
                                    className={`entity ${"entity_advice"}`}
                                >
                                    <p className={`entity__title ${"entity__title_white"}`}>
                                        {t("recommendations")}
                                    </p>
                                    <p
                                        className={`entity__description  ${"entity__description_white"}`}
                                    >
                                        {t("available_for_pupil")}
                                    </p>
                                    <span className={`entity__extra `}>{advices.length}</span>
                                </Link>
                                <Link
                                    to={`/notes?pupilId=${pupilId}`}
                                    className="entity entity_note"
                                >
                                    <p className="entity__title entity__title_white">
                                        {t("notes")}
                                    </p>
                                    <p className="entity__description entity__description_white">
                                        {t("not_available_for_pupil")}
                                    </p>
                                    <span className={`entity__extra`}>{notes.length}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
