export const IS_PRODUCTION = process.env.REACT_APP_MODE === "production";
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const API_URL = process.env.REACT_APP_API_URL || "/api/";
export const CONSTRUCTOR_LINK =
    process.env.REACT_APP_CONSTRUCTOR_LINK || "https://constructor.testu.online";
export const VK_API_URL = "https://vk.com/js/api/openapi.js?169";
export const VK_APP_ID = process.env.REACT_APP_VK_APP_ID;
export const VK_AUTH_CODE_KEY = process.env.REACT_APP_VK_AUTH_CODE_KEY;
export const APP_LANG = process.env.REACT_APP_LANG || "ru";
export const TG_BOT_URL = process.env.REACT_APP_TG_BOT_URL;
