import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useCreateReport } from "hooks/useCreateReport";
import { declinations } from "helpers/declinations";
import { BackButton } from "components/BackButton";
import { Range } from "components/Range";
import { Layout } from "components/Layout";

/**
 * @api {get} /statistic/schools/:id статистика классов школ
 * @apiGroup statistic
 * @apiName статистика классов школ
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница статистики классов выбранной школы</p>
 * <p>Компонент <code>StatisticClasses.js</code> <a href="../client/src/pages/statistic/StatisticClasses.js">ссылка на файл</a></p>
 */

export const StatisticClasses = () => {
    const { t, i18n } = useTranslation("statistic");
    const schoolId = useParams().id;
    const [school, setSchool] = useState(null);
    const [classes, setClasses] = useState([]);
    const { createReport, isReportLoading } = useCreateReport();

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`statistic/schools/${schoolId}`);
                setClasses(data.classes);
                setSchool(data.school);
            })();
        }
    }, [schoolId]);

    const createReportHandler = () => {
        createReport(`reports/school/${schoolId}`, `${t("summary_statistics_pupils")}.xlsx`);
    };

    return (
        <Layout>
            <div className="page full-header">
                <header className="page__header full-header">
                    <BackButton />
                    <p className="page__title">{school || t("statistic")}</p>
                </header>

                {!!classes.length && (
                    <div
                        className="statistic-reports__btn statistic-reports__btn-class"
                        onClick={createReportHandler}
                    >
                        {isReportLoading ? (
                            <p>{t("report_loading", { ns: "translation" })}</p>
                        ) : (
                            <p>{t("create_summary_statistics_pupils")}</p>
                        )}
                    </div>
                )}

                <div>
                    {classes.map((item) => (
                        <Link
                            key={item._id}
                            to={`/statistic/classes/${item._id}`}
                            className="statistic-condition"
                        >
                            <p className="statistic-condition__title">
                                {item.number} {item.letter.toUpperCase()}
                            </p>

                            <Range
                                total={item.total}
                                norm={item.positively}
                                // notNorm={item.negatively}
                                notNorm={item.total - item.positively}
                            />

                            <div className="statistic-condition__footer">
                                <span className="statistic-condition__indicator green">
                                    {item.positively}
                                </span>
                                {/*<span className="statistic-condition__indicator red">*/}
                                {/*    {item.negatively}*/}
                                {/*</span>*/}
                                <span className="statistic-condition__indicator blue">
                                    {/*{item.total - item.positively - item.negatively}*/}
                                    {item.total - item.positively}
                                </span>
                                <p className="statistic-condition__members">
                                    {item.total} {declinations(item.total, "test", i18n.language)}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    );
};
